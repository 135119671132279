import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Card,
  Button,
  InputGroup,
  FormControl,
  Form,
  Popover,
  Overlay,
} from 'react-bootstrap';

import './home.scss';

import { GameType } from './game/game';
import { useGames } from './game/game.hook';
import { NetworkError } from './util/fetch';
import { useNotifications } from './notification/notification.hook';

interface JoinArgs {
  onJoin: (code: string) => void;
  disabled: boolean;
}

const JoinCode: React.FC<JoinArgs> = ({ onJoin, disabled }) => {
  const [joinCode, setCode] = useState<string>('');
  const [validated, setValidated] = useState(false);

  const onSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (!disabled && form.checkValidity() === true) {
      onJoin(joinCode);
    }
  };
  return (
    <Form noValidate validated={validated} onSubmit={onSubmit}>
      <Form.Group className="home__join-form">
        <InputGroup className="home__join-code">
          <FormControl
            disabled={disabled}
            className="code__input"
            placeholder="e.g. ABCD"
            value={joinCode}
            onChange={(e) => setCode(e.target.value)}
            type="text"
            required
            pattern=".{4}"
            aria-label="Join code"
          />
          <InputGroup.Append>
            <Button
              className="join__button"
              variant="primary"
              disabled={disabled}
              type="submit"
            >
              Join
            </Button>
          </InputGroup.Append>
          <Form.Control.Feedback type="invalid">
            Enter a four letter join code
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

interface ActionArgs {
  type: GameType;
  disabled: boolean;
  loading: boolean;
  onDelete: (id: string) => void;
}

const GameActions: React.FC<ActionArgs> = ({
  type,
  disabled,
  loading,
  onDelete,
}) => {
  const { games } = useGames();
  const [create, setCreate] = useState(false);
  const [resume, setResume] = useState(false);
  const [deleteGame, setDelete] = useState(false);
  const target = useRef(null);
  const game = games.find((g) => g.gameType === type);

  if (loading) {
    return null;
  } else if (resume && game) {
    return (
      <Redirect
        push
        to={{ pathname: `/${game.gameType}/${game.lookup}` }}
      ></Redirect>
    );
  } else if (create) {
    return <Redirect push to={{ pathname: `/create/${type}` }}></Redirect>;
  }

  if (!game) {
    return (
      <Button
        variant="primary"
        onClick={() => setCreate(true)}
        disabled={disabled}
      >
        Host game
      </Button>
    );
  }

  return (
    <>
      <Button
        className="home__action"
        variant="primary"
        onClick={() => setResume(true)}
      >
        Resume
      </Button>

      <Button
        className="home__action"
        ref={target}
        variant="outline-danger"
        onClick={() => setDelete(!deleteGame)}
      >
        End game
      </Button>
      <Overlay target={target.current} placement="top" show={deleteGame}>
        <Popover id="delete-popover">
          <Popover.Title as="h3">End game</Popover.Title>
          <Popover.Content>
            <Button
              className="delete-confirm__action"
              size="sm"
              onClick={() => setDelete(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={() => onDelete(game.id)}
            >
              Confirm
            </Button>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const { getByLookup, loadAll, deleteGame } = useGames();
  const { error } = useNotifications();
  const [joinDetails, setJoin] = useState<[string, string] | undefined>(
    undefined,
  );

  useEffect(() => {
    loadAll()
      .catch((err: NetworkError) => error(err.message))
      .finally(() => setLoading(false));
  }, [error, loadAll]);

  const joinGame = (lookup: string) => {
    lookup = lookup.toUpperCase();
    setDisabled(true);
    getByLookup(lookup)
      .then((game) => {
        setDisabled(false);
        setJoin([game.gameType, game.lookup]);
      })
      .catch((err: NetworkError) => {
        error(err.message);
        setDisabled(false);
      });
  };

  const endGame = (id: string) => {
    setDisabled(true);
    deleteGame(id).finally(() => setDisabled(false));
  };

  if (joinDetails) {
    const [type, lookup] = joinDetails;
    return <Redirect push to={{ pathname: `/join/${type}/${lookup}` }} />;
  }

  return (
    <div className="home__container">
      <header>
        <h1>Games</h1>
      </header>
      <div className="home__body">
        <Card className="home__card">
          <Card.Img variant="top" src="/kot/splash.jpg" />
          <Card.Body>
            <Card.Title>King of Tokyo</Card.Title>
            <GameActions
              loading={loading}
              disabled={disabled}
              type={GameType.KingOfTokyo}
              onDelete={(id) => endGame(id)}
            />
          </Card.Body>
        </Card>
        <Card className="home__card home__join">
          <Card.Img variant="top" src="/join-splash.png" />
          <Card.Body>
            <Card.Title>Join game</Card.Title>
            <JoinCode onJoin={joinGame} disabled={disabled} />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Home;
