import { useCallback } from 'react';
import { useGamesState } from '../State';

export enum NotificationType {
  Error,
  Info,
}

let notificationId = 1;

export interface Notification {
  id: number;
  type: NotificationType;
  message: string;
  duration?: number;
}

export function useNotifications() {
  const { notifications, setNotifications } = useGamesState();

  const error = useCallback(
    (message: string | string[], duration?: number) => {
      const newMessages = typeof message === 'string' ? [message] : message;
      const toCreate = newMessages.map((c) => ({
        id: notificationId++,
        type: NotificationType.Error,
        message: c,
        duration,
      }));
      const updated = [...notifications, ...toCreate];
      setNotifications(updated);
    },
    [notifications, setNotifications],
  );

  const info = useCallback(
    (message: string | string[], duration?: number) => {
      const newMessages = typeof message === 'string' ? [message] : message;
      const toCreate = newMessages.map((c) => ({
        id: notificationId++,
        type: NotificationType.Info,
        message: c,
        duration,
      }));
      const updated = [...notifications, ...toCreate];
      setNotifications(updated);
    },
    [notifications, setNotifications],
  );

  const remove = useCallback(
    (id: number) => {
      setNotifications(notifications.filter((n) => n.id !== id));
    },
    [notifications, setNotifications],
  );

  return {
    error,
    info,
    remove,
    notifications,
  };
}
