import React, { useState } from 'react';
import { match, Redirect } from 'react-router-dom';

import './create.scss';

import { CardGroup, Card } from 'react-bootstrap';
import { GameType } from './game';
import { useGames } from './game.hook';
import PlayerName from './PlayerName';

interface CreateArgs {
  match: match<{ type: string }>;
}

const getSplash = (type: GameType): string => {
  switch (type) {
    case GameType.KingOfTokyo:
    default:
      return '/kot/splash.jpg';
  }
};

const Create: React.FC<CreateArgs> = ({ match }: CreateArgs) => {
  const type = match.params.type as GameType;

  const { create } = useGames();

  const [redirectTo, setRedirect] = useState<string | undefined>(undefined);
  const [creating, setCreating] = useState(false);

  const handleSubmit = () => {
    if (!creating) {
      setCreating(true);
      create(type).then((game) => {
        setCreating(false);
        if (game) {
          setRedirect(game.lookup);
        }
      });
    }
  };

  if (redirectTo) {
    return <Redirect to={{ pathname: `/${type}/${redirectTo}` }}></Redirect>;
  }

  return (
    <div className="create__container">
      <CardGroup>
        <Card>
          <Card.Img variant="top" src={getSplash(type)} />
          <Card.Body>
            <PlayerName
              disabled={creating}
              onSubmit={handleSubmit}
              submitLabel="Start"
            />
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
};

export default Create;
