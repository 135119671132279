export const buildUrl = (path: string) => {
  if (process.env.NODE_ENV !== 'production') {
    return path;
  } else {
    return process.env.REACT_APP_API_BASE + path;
  }
};

export class NetworkError extends Error {
  constructor(public status: number, message: string) {
    super(message);
  }
}

const handleResponse = <T>(response: any) => {
  if (response.status >= 400 && response.status < 600) {
    return response.json().then((err: any) => {
      throw new NetworkError(response.status, err.error);
    });
  } else if (response.status === 204) {
    return Promise.resolve(null);
  }

  return response.json() as T;
};

const getFetch = <T>(url: string): Promise<T> => {
  const headers: any = {
    Accept: 'application/json, text/plain, */*',
  };

  return fetch(buildUrl(url), {
    headers,
    credentials: 'include',
  }).then((resp) => handleResponse<T>(resp));
};

const postFetch = <T>(url: string, body: any): Promise<T> => {
  const headers: any = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  };

  return fetch(buildUrl(url), {
    method: 'POST',
    headers,
    credentials: 'include',
    body: body ? JSON.stringify(body) : null,
  }).then((resp) => handleResponse<T>(resp));
};

const putFetch = <T>(url: string, body: any): Promise<T> => {
  const headers: any = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  };

  return fetch(buildUrl(url), {
    method: 'PUT',
    headers,
    credentials: 'include',
    body: body ? JSON.stringify(body) : null,
  }).then((resp) => handleResponse<T>(resp));
};

const deleteFetch = <T>(url: string): Promise<T> => {
  const headers: any = {
    Accept: 'application/json, text/plain, */*',
  };

  return fetch(buildUrl(url), {
    method: 'DELETE',
    headers,
    credentials: 'include',
  }).then((resp) => handleResponse<T>(resp));
};

export const get = getFetch;

export const post = postFetch;

export const put = putFetch;

export const del = deleteFetch;
