import { useGamesState } from '../State';
import { GameService } from './game.service';
import { useCallback } from 'react';
import { GameType, Game } from './game';
import { useNotifications } from '../notification/notification.hook';

const gameService = new GameService();

export function useGames() {
  const { error } = useNotifications();
  const { games, setGames } = useGamesState();

  const create = useCallback(
    (gameType: GameType): Promise<Game | void> => {
      return gameService
        .createGame(gameType)
        .then((game) => {
          setGames([...games, game]);
          return game;
        })
        .catch((err) => error(err.message));
    },
    [games, setGames, error],
  );

  const getByLookup = useCallback(
    async (lookup: string): Promise<Game> => {
      const existingGame = games.find((g) => g.lookup === lookup.toUpperCase());
      if (existingGame) {
        return existingGame;
      }

      const game = await gameService.getGameByLookup(lookup);
      if (games.every((g) => g.id !== game.id)) {
        setGames([...games, game]);
      }

      return game;
    },
    [games, setGames],
  );

  const loadAll = useCallback(async (): Promise<void> => {
    const fetchedGames = await gameService.getGames();
    const newGames = fetchedGames.filter((g) =>
      games.every((game) => game.id !== g.id),
    );
    if (newGames.length) {
      const updated = [...games, ...newGames];
      setGames(updated);
    }
  }, [games, setGames]);

  const setJoined = useCallback(
    (lookup: string) => {
      lookup = lookup.toUpperCase();
      const game = games.find((g) => g.lookup === lookup) as Game;
      if (!game) {
        return;
      }
      const updated = [
        ...games.filter((g) => g.lookup !== lookup),
        { ...game, hasJoined: true },
      ];
      setGames(updated);
    },
    [setGames, games],
  );

  const deleteGame = useCallback(
    (id: string) => {
      return gameService.deleteGame(id).then(() => {
        const updated = games.filter((g) => g.id !== id);
        setGames(updated);
      });
    },
    [games, setGames],
  );

  const hasJoined = useCallback(
    (lookup: string) => {
      lookup = lookup.toUpperCase();
      return games.find((g) => g.lookup === lookup)?.hasJoined || false;
    },
    [games],
  );

  return {
    create,
    getByLookup,
    hasJoined,
    setJoined,
    games,
    loadAll,
    deleteGame,
  };
}
