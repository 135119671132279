import { useState, useEffect, useCallback } from 'react';
import { post, get } from './util/fetch';

let signingIn = false;

export function useAuth() {
  const [signedIn, setSignedIn] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    get('/auth')
      .then(() => setSignedIn(true))
      .catch(() => setSignedIn(false));
  }, []);

  const signIn = useCallback(() => {
    if (!signingIn) {
      signingIn = true;
      post('/auth/signin', {})
        .then(() => setSignedIn(true))
        .finally(() => (signingIn = false));
    }
  }, []);

  return { signedIn, signIn };
}
