import React from 'react';

import './loading.scss';

const Loading: React.FC = () => {
  return (
    <div className="loading__container">
      <div className="loading__bar">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    </div>
  );
};

export default Loading;
