import React, { useRef, useState, useEffect } from 'react';
import { usePlayer } from './player.hook';
import { Form, Button } from 'react-bootstrap';

interface PlayerNameArgs {
  disabled: boolean;
  submitLabel: string;
  onSubmit: (name: string) => void;
}

const PlayerName: React.FC<PlayerNameArgs> = ({
  disabled,
  onSubmit,
  submitLabel,
}) => {
  const inputEl = useRef<HTMLInputElement>(null);

  const { setPlayerName, playerName } = usePlayer();
  const [formName, setFormName] = useState<string>('');
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    if (!formName && !initialised && playerName) {
      setFormName(playerName);
      setInitialised(true);
    }
  }, [formName, playerName, initialised]);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (!disabled && form.checkValidity() === true) {
      setPlayerName(formName);
      onSubmit(formName);
    }
  };

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.focus();
    }
  }, [inputEl]);

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group controlId="playerId">
        <Form.Label>Player name</Form.Label>
        <Form.Control
          value={formName}
          onChange={(e) => setFormName(e.target.value)}
          ref={inputEl}
          type="text"
          placeholder="Player name"
          required
        />
        <Form.Control.Feedback type="invalid">
          Please enter a player name
        </Form.Control.Feedback>
      </Form.Group>

      <Button disabled={disabled} variant="primary" type="submit">
        {submitLabel}
      </Button>
    </Form>
  );
};

export default PlayerName;
