import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimesCircle,
  faInfoCircle,
  faChevronLeft,
  faHeart,
  faBolt,
  faStar,
  faExpandAlt,
} from '@fortawesome/free-solid-svg-icons';

import Div100vh from 'react-div-100vh';

import Home from './Home';
import Create from './game/Create';
import GamesState from './State';
import Notifications from './notification/Notifications';
import Loading from './util/Loading';
import { useAuth } from './auth.hook';
import Join from './game/Join';

library.add(
  faTimesCircle,
  faInfoCircle,
  faChevronLeft,
  faHeart,
  faBolt,
  faStar,
  faExpandAlt,
);

const KingOfTokyo = lazy(() => import('./king-of-tokyo/KingOfTokyo'));

function App() {
  const { signedIn, signIn } = useAuth();

  useEffect(() => {
    if (signedIn === false) {
      signIn();
    }
  }, [signedIn, signIn]);

  if (!signedIn) {
    return (
      <Div100vh>
        <Loading></Loading>
      </Div100vh>
    );
  }

  return (
    <Div100vh>
      <GamesState>
        <Notifications></Notifications>
        <Router>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/create/:type" component={Create} />
              <Route path="/join/:type/:lookup" component={Join} />
              <Route path="/KingOfTokyo/:id" component={KingOfTokyo} />
              <Route path="/" component={Home} />
            </Switch>
          </Suspense>
        </Router>
      </GamesState>
    </Div100vh>
  );
}

export default App;
