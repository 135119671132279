import React, { createContext, useContext, useState } from 'react';
import { Notification } from './notification/notification.hook';
import { Game } from './game/game';

export interface GamesState {
  playerName?: string;
  setPlayerName: (name: string) => void;
  notifications: Notification[];
  setNotifications: (messages: Notification[]) => void;
  games: Game[];
  setGames: (games: Game[]) => void;
}

const defaultServices: GamesState = {
  setPlayerName: () => {},
  notifications: [],
  setNotifications: () => {},
  games: [],
  setGames: () => {},
};

const StateContext = createContext<GamesState>(defaultServices);

interface StateArgs {
  children: any;
}

export const useGamesState = () => useContext(StateContext);

function GamesState({ children }: StateArgs) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [games, setGames] = useState<Game[]>([]);
  const [playerName, setPlayerName] = useState<string | undefined>(undefined);

  const state = {
    playerName,
    setPlayerName,
    notifications,
    setNotifications,
    games,
    setGames,
  };

  return (
    <StateContext.Provider value={state}>{children}</StateContext.Provider>
  );
}

export default GamesState;
