export enum GameType {
  KingOfTokyo = 'KingOfTokyo',
}

export interface Game {
  id: string;
  gameType: GameType;
  lookup: string;
  hasJoined: boolean;
}
