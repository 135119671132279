import { post, get, del } from '../util/fetch';
import { GameType, Game } from './game';

export class GameService {
  createGame(gameType: GameType): Promise<Game> {
    return post<Game>('/game', {
      gameType: gameType,
    });
  }

  getGames(): Promise<Game[]> {
    return get<Game[]>('/game');
  }

  deleteGame(id: string): Promise<void> {
    return del<void>(`/game/${id}`);
  }

  getGameByLookup(lookup: string): Promise<Game> {
    return get<Game>(`/game/lookup/${lookup}`);
  }
}
