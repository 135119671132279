import React from 'react';
import { Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useNotifications, NotificationType } from './notification.hook';

import './notifications.scss';

const getIcon = (type: NotificationType) => {
  switch (type) {
    case NotificationType.Error:
      return 'times-circle';
    default:
      return 'info-circle';
  }
};

const Notifications: React.FC = () => {
  const { notifications, remove } = useNotifications();
  const first = notifications[0];

  if (!first) {
    return null;
  }
  const duration = first.duration && first.duration > 0 ? first.duration : 3500;

  return (
    <div className="notification__container">
      <Toast
        key={first.id}
        className={`notification__message ${
          first.type === NotificationType.Error
            ? 'notification__error'
            : 'notification__info'
        }`}
        onClose={() => remove(first.id)}
        delay={duration}
        autohide
      >
        <Toast.Body>
          <FontAwesomeIcon
            className="notification__icon"
            icon={getIcon(first.type)}
          />
          {first.message}
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default Notifications;
