import { useEffect, useCallback } from 'react';

import { useGamesState } from '../State';

const PLAYER_NAME_KEY = 'games.player-name';

export function usePlayer() {
  const { playerName, setPlayerName: setPlayerNameState } = useGamesState();

  useEffect(() => {
    if (!playerName) {
      let storedName = localStorage.getItem(PLAYER_NAME_KEY) ?? undefined;
      storedName = storedName?.trim();

      if (storedName) {
        setPlayerNameState(storedName);
      }
    }
  }, [playerName, setPlayerNameState]);

  const setPlayerName = useCallback(
    (name: string) => {
      name = name?.trim();
      if (name) {
        localStorage.setItem(PLAYER_NAME_KEY, name);
      }

      setPlayerNameState(name);
    },
    [setPlayerNameState],
  );

  return {
    playerName,
    setPlayerName,
  };
}
