import React, { useState, useEffect } from 'react';
import { Redirect, match } from 'react-router-dom';

import './join.scss';

import { useGames } from './game.hook';
import { CardGroup, Card } from 'react-bootstrap';
import PlayerName from './PlayerName';
import { useNotifications } from '../notification/notification.hook';
import { NetworkError } from '../util/fetch';
import { Game } from './game';
import Loading from '../util/Loading';

interface JoinArgs {
  match: match<{ type: string; lookup: string }>;
}

const Join: React.FC<JoinArgs> = ({ match }) => {
  const { lookup, type } = match.params;
  const { getByLookup } = useGames();
  const [game, setGame] = useState<Game | undefined>();
  const { error } = useNotifications();

  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    getByLookup(lookup)
      .then((game) => setGame(game))
      .catch((err: NetworkError) => {
        error(err.message);
        if (err.status === 404) {
          setNotFound(true);
        }
      });
  }, [lookup, getByLookup, error]);

  const [redirect, setRedirect] = useState<boolean>(false);

  const handleSubmit = () => {
    setRedirect(true);
  };

  if (!game) {
    return <Loading />;
  }

  if (notFound) {
    return <Redirect to={{ pathname: '/' }} />;
  } else if (game?.hasJoined || redirect) {
    return <Redirect to={{ pathname: `/${type}/${lookup}` }} />;
  }

  return (
    <div className="join__container">
      <CardGroup>
        <Card>
          <Card.Img variant="top" src="/join-splash.png" />
          <Card.Body>
            <PlayerName
              disabled={false}
              onSubmit={handleSubmit}
              submitLabel="Join"
            />
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
};

export default Join;
